/* write your css here */
.gradient-text {
  background: linear-gradient(82deg, #37adf5 0%, #CC51A9 20%, #41f385 40%, #4185f3 65%, #37adf5 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  }

.loading-gradient-text {
  background: radial-gradient(circle at 100%, #37adf5 0%, #CC51A9 20%, #41f385 40%, #4185f3 65%, #37adf5 100%);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: animatedgradient 2.5s linear infinite;
  animation: animatedgradient 2.5s linear infinite;
  }

  @-webkit-keyframes animatedgradient {
    to {
      background-position: 200% center;
    }
  }
  
  @keyframes animatedgradient {
    to {
      background-position: 200% center;
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .containerWidth{
    width: 45%;
  }
  .faqItem{
    width: 48%;
  }

  @media screen and (max-width: 1522px) {
    .containerWidth{
      width: 60%;
    }
    .chatArea{
        padding-top: 2rem;
    }
    
  }
  @media screen and (max-width: 1261px) {
    .containerWidth{
      width: 70%;
    }
    
  }
  @media screen and (max-width: 1085px) {
    .containerWidth{
      width: 80%;
    }
    
  }
  @media screen and (max-width: 955px) {
    .containerWidth{
      width: 85%;
    }
    
  }
  @media screen and (max-width: 900px) {
    .faqContainer{
     flex-direction: column;
    }
    .faqItem{
      width: 100%;
    }
    
  }
  @media screen and (max-width: 626px) {
   .aiHeading{
    font-size: 24px !important;
   }
    
  }
  @media screen and (max-width: 481px) {
    .containerWidth{
        width: 95%;
      }
      .aiHeading{
        font-size: 20px !important;
       }
    
  }
  @media screen and (max-width: 428px) {
    .navbarHeading{
        font-size: 14px !important;
    }
  }
  @media screen and (max-width: 390px) {
    .aiHeading{
        font-size: 18px !important
    }
   .faqQa{
    font-size: 13px !important;
   }
   .faqText{
    margin-top: 0.5px !important;
    font-size: 11px !important;
   }
  }
  body {
    font-family: sans-serif;
    font-size: 2em;
}
.ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}
.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
@-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes ellipsis-dot {
      0% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 0; }
}